/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import Page from '../components/layout/Page';
import Column from '../components/Column';
import Slideshow from '../components/Slideshow';

const ScenePage = ({ data }) => (
  <Page title="活動花絮">
    <Column>
      <Slideshow
        tw="mx-auto"
        link="https://drive.google.com/drive/folders/1nwaaapbVTE4rcg6rLiAPJJ_N2eCag-6A"
        images={data.allFeaturedPhotosJson.nodes[0].photos}
      />
    </Column>
  </Page>
);

export default ScenePage;

export const query = graphql`
  {
    allFeaturedPhotosJson {
      nodes {
        photos {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allFeaturedPhotosWithTopicJson {
      nodes {
        topic
        link
        photos {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
